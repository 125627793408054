import { Component } from '@angular/core';
import {LocaleService } from '../i18n/locale.service';

@Component({
  selector: 'sgt-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  constructor(private localeService:LocaleService) {
    this.localeService.observable.subscribe(() => {
      this.locale = this.localeService.getLocale()
  });
  };

  locale = this.localeService.getLocale()
}