import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from './locale.service';

import en from './json/en.json';
import fr from './json/fr.json';
import de from './json/de.json';
import cn from './json/cn.json';
import ar from './json/ar.json';
import ru from './json/ru.json';

@Pipe({name: 'i18n', pure: false})
export class I18nPipe implements PipeTransform {

  private i18n;
  constructor( private localeService: LocaleService ) {
    this.i18n = [];

    this.i18n['en'] = en;
    this.i18n['fr'] = fr;
    this.i18n['de'] = de;
    this.i18n['cn'] = cn;
    this.i18n['ar'] = ar;
    this.i18n['ru'] = ru;
  }

  transform(key: string): string {
    return this.i18n[this.localeService.getLocale()][key];
  }
}