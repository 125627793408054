import { Component } from '@angular/core';

@Component({
  selector: 'sgt-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})
export class ObjectsComponent {
  imagesPreciousObjects = [
    "../../assets/photos/precious-objects/oeuf1.jpg",
    "../../assets/photos/precious-objects/oeuf2.jpg",
    "../../assets/photos/precious-objects/oeuf3.jpg",    
    "../../assets/photos/precious-objects/1.jpg",
    "../../assets/photos/precious-objects/2.jpg",
    "../../assets/photos/precious-objects/3.jpg",
    "../../assets/photos/precious-objects/5.jpg",
    "../../assets/photos/precious-objects/6.jpg",
    "../../assets/photos/precious-objects/8.jpg",   
    "../../assets/photos/precious-objects/9.jpg", 
    "../../assets/photos/precious-objects/10.jpg", 
    "../../assets/photos/precious-objects/11.jpg", 
    "../../assets/photos/precious-objects/12.jpg", 
    "../../assets/photos/precious-objects/13.jpg", 
    "../../assets/photos/precious-objects/14.jpg", 
    "../../assets/photos/precious-objects/15.jpg", 
    "../../assets/photos/precious-objects/16.jpg", 
    "../../assets/photos/precious-objects/17.jpg", 
    "../../assets/photos/precious-objects/18.jpg", 
    "../../assets/photos/precious-objects/19.jpg", 
    "../../assets/photos/precious-objects/20.jpg", 
    "../../assets/photos/precious-objects/21.jpg", 
    "../../assets/photos/precious-objects/22.jpg", 
    "../../assets/photos/precious-objects/23.jpg", 
    "../../assets/photos/precious-objects/24.jpg", 
    "../../assets/photos/precious-objects/25.jpg", 
    "../../assets/photos/precious-objects/26.jpg", 
    "../../assets/photos/precious-objects/27.jpg", 
    "../../assets/photos/precious-objects/29.jpg", 
    "../../assets/photos/precious-objects/30.jpg", 
    "../../assets/photos/precious-objects/31.jpg", 
    "../../assets/photos/precious-objects/32.jpg", 
    "../../assets/photos/precious-objects/33.jpg", 
    "../../assets/photos/precious-objects/34.jpg", 
    "../../assets/photos/precious-objects/35.jpg", 
    "../../assets/photos/precious-objects/36.jpg", 
    "../../assets/photos/precious-objects/37.jpg", 
    "../../assets/photos/precious-objects/38.jpg", 
    "../../assets/photos/precious-objects/39.jpg", 
    "../../assets/photos/cristal/4.jpg",
    "../../assets/photos/cristal/5.jpg",
    "../../assets/photos/cristal/7.jpg",
    "../../assets/photos/cristal/8.jpg",
    "../../assets/photos/cristal/10.jpg",
    "../../assets/photos/cristal/11.jpg",
    "../../assets/photos/cristal/12.jpg",
    "../../assets/photos/silver/1.jpg",
    "../../assets/photos/silver/2.jpg"
  ];
  
  height = "50vh";
}