import { Component } from '@angular/core';
import {LocaleService } from './i18n/locale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private localeService:LocaleService) {};

  setLocale(locale:string) {
    this.localeService.setLocale(locale);
  }

  relocate() {
    document.getElementById('left').scrollIntoView();
  }

  year = (new Date()).getFullYear();
}