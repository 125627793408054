import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class LocaleService {

    
  locale = 'de';

    // Observable string sources
    private callSource = new Subject<any>();  
    // Observable string streams
    observable = this.callSource.asObservable();

  constructor() { }

  getLocale():string {
      return this.locale;
  }

  setLocale(locale:string) {
      this.locale = locale;
      this.callSource.next();
  }

}