import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocaleService } from '../i18n/locale.service';
import { HttpHeaders } from '@angular/common/http';

class Item {
    images: string[]
    description: string
    price: Number
    ref: string
}

class Email {
    sender: string
    tel: string
    name: string
    message: string
    ref: string
    locale: string
}

class Filter {
    name: string
    id: number
    checked: boolean
}

class FiltersResponse {
    gemsFilters: Filter[]
    jewelsFilters: Filter[]
}

class Gem {
    id: number
    desc: string
    ref: string
    carat: number
    colour: string 
    price: number
    mainPhoto: string
    photos: string[]
    videos: string[]
}

class Jewel {
    id: number
    desc: string
    ref: string 
    size: number 
    price: number
    mainPhoto: string
    photos: string[]
    videos: string[]
}

class ItemsResponse {
    gems: Gem[]
    jewels: Jewel[]
}

@Component({
  selector: 'sgt-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent {
    constructor(private http: HttpClient, 
                public orderDialog: MatDialog,
                private localeService: LocaleService
                ) {
        this.getFilters()   
        this.localeService.observable.subscribe(() => {
            this.locale = this.localeService.getLocale()

            this.gemsFilters = []
            this.jewelsFilters = []
            this.getFilters()

            if (this.searched) {
                this.gems = []
                this.jewels = []
                this.search()
            }
        });
    
     }
    ngOnInit(): void {
    }

    locale = this.localeService.getLocale()

    invalidMessage = false
    invalidTel = false
    invalidSender = false
    invalidName = false
    invalidCgv = false
    cgvChecked = false
    searched = false
    gemsFilters = [] as Filter[]
    jewelsFilters = [] as Filter[]
    gemsToggles = [] as boolean[]
    jewelsToggles = [] as boolean[]
    gems = [] as Gem[]
    jewels = [] as Jewel[]
    loading = false
    ack = false
    height = "35vh";
    BACK_URL = "https://swissgemstrade.ovh:443";
    //BACK_URL = "http://localhost:3000";
    popupPhoto = ""

    orderEmail = {} as Email

    headers = new HttpHeaders({
        'Access-Control-Allow-Origin':  '*'
    })

    getFilters() {
        this.loading = true

        let params = new HttpParams().set("locale", this.localeService.getLocale())

        this.http.get(this.BACK_URL + "/filters", { "params": params, "headers": this.headers })
            .subscribe((data: FiltersResponse) => {
                console.log(data)
                this.gemsFilters = data.gemsFilters
                this.jewelsFilters = data.jewelsFilters
                this.loading = false
            }, error => console.log(error));
    }

    search() {
        //let gemsFiltersIds = [] as number[]
        let jewelsFiltersIds = [] as number[]
        this.loading = true
        /*
        for (let filter of this.gemsFilters) {
            if (filter.checked) {
                gemsFiltersIds.push(filter.id)
            }
        }*/
        for (let filter of this.jewelsFilters) {
            if (filter.checked) {
                jewelsFiltersIds.push(filter.id)
            }
        }

        let params = new HttpParams()
            .set("locale", this.localeService.getLocale())
            //.set("gemsFilters", encodeURIComponent(JSON.stringify(gemsFiltersIds)))
            .set("jewelsFilters", encodeURIComponent(JSON.stringify(jewelsFiltersIds)))

        this.http.get(this.BACK_URL + "/items", { "params": params, "headers": this.headers })
        .subscribe((data: ItemsResponse) => {
            this.searched = true
            //this.gems = data.gems
            this.jewels = data.jewels
            //this.gems.forEach(gem => { gem.mainPhoto = gem.photos.length > 0 ? gem.photos[0] : "" })
            this.jewels.forEach(jewel => { jewel.mainPhoto = jewel.photos.length > 0 ? jewel.photos[0] : "" })
            this.loading = false
        }, error => console.log(error));
    }

    replaceGemMainPhoto(index: number, newPhoto: string) {
        this.gems[index].mainPhoto = newPhoto
    }

    replaceJewelMainPhoto(index: number, newPhoto: string) {
        this.jewels[index].mainPhoto = newPhoto
    }

    truncate(input: string) {
        /*let width = this.elem.nativeElement.offsetWidth
        let charNb = (width - 150) / 10
        console.log(charNb)
        if (input.length > charNb)
           return input.substring(0, charNb) + '...';
        else
           return input;*/

        return input;
    };

    orderItem(ref: string) {
        console.log("order item: " + ref)
        this.orderEmail.ref = ref

        this.openOrderDialog()
    }

    closePopups() {
        this.closeOrderDialog()
        this.closePhotoPopup()
    }

    closeOrderDialog() {
        var popup = document.getElementById("order-dialog");
        popup.classList.remove("show");

        var popupScreen = document.getElementById("popup-screen");
        popupScreen.classList.remove("show");

        this.orderEmail.message = "";
        this.orderEmail.name = "";
        this.orderEmail.ref = "";
        this.orderEmail.tel = "";
        this.orderEmail.sender = "";
    }

    openOrderDialog() {
        var popup = document.getElementById("order-dialog");
        popup.classList.add("show");

        var popupScreen = document.getElementById("popup-screen");
        popupScreen.classList.add("show");
    }

    closePhotoPopup() {
        var popup = document.getElementById("popup-photo");
        popup.classList.remove("show");

        var popupScreen = document.getElementById("popup-screen");
        popupScreen.classList.remove("show");
    }

    openPhotoPopup(photo) {
        console.log("popup photo: " + photo)
        this.popupPhoto = photo;
        var popup = document.getElementById("popup-photo");
        popup.classList.add("show");

        var popupScreen = document.getElementById("popup-screen");
        popupScreen.classList.add("show");
    }

    sendEmails(email: Email) {
        this.invalidSender = false
        this.invalidMessage = false
        this.invalidTel = false
        this.invalidSender = false
        this.invalidName = false
        this.invalidCgv = false
        if (email.sender == null || email.sender == "") {
            this.invalidSender = true
        }
        if (email.message == null || email.message == "") {
            this.invalidMessage = true
        }
        if (email.name == null || email.name == "") {
            this.invalidName = true
        }
        if (email.tel == null || email.tel == "") {
            this.invalidTel = true
        }
        if (!this.cgvChecked) {
            this.invalidCgv = true
        }
        if (this.invalidCgv || this.invalidSender || this.invalidMessage
             || this.invalidName || this.invalidTel) {
                 return
             }

        this.ack = false
        this.loading = true

        email.locale = this.localeService.getLocale();
        console.log("sending email: ", email)

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Origin': 'swissgemstrade.com',
            })
        };

        this.http.post(this.BACK_URL + "/email", email)
            .subscribe((data: boolean) => {
                this.ack = data
                this.loading = false
            });

        this.invalidSender = false
        this.invalidMessage = false
        this.invalidTel = false
        this.invalidSender = false
        this.invalidName = false
        this.invalidCgv = false
        this.cgvChecked = false

        this.closeOrderDialog()
    }

    toggleGem(id: string) {
        console.log("toggle gem")
        this.gemsToggles[id] = true
    }

    toggleJewel(id: string) {
        console.log("toggle jewel")
        this.jewelsToggles[id] = true
    }
}