import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

import {SlideshowModule} from 'ng-simple-slideshow';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { InvestmentComponent } from './investment/investment.component';
import { StonesComponent } from './stones/stones.component';
import { ObjectsComponent } from './objects/objects.component';
import { JewelsComponent } from './jewels/jewels.component';
import { ContactComponent } from './contact/contact.component';
import { EstimationComponent } from './estimation/estimation.component';
import { CatalogueComponent } from './catalogue/catalogue.component';

import { I18nPipe } from './i18n/i18n.pipe';

import 'hammerjs';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    InvestmentComponent,
    StonesComponent,
    ObjectsComponent,
    JewelsComponent,
    ContactComponent,
    EstimationComponent,
    CatalogueComponent,
    I18nPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    AppRoutingModule,
    SlideshowModule,
    MatTabsModule,
    HttpClientModule,
    MatDialogModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    MatMenuModule,
    MatButtonModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
