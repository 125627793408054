import { Component } from '@angular/core';

@Component({
  selector: 'sgt-stones',
  templateUrl: './stones.component.html',
  styleUrls: ['./stones.component.scss']
})
export class StonesComponent {
  height = "50vh";

  imagesEmerald = [
    "../../assets/photos/emerald/1.jpg",
    "../../assets/photos/emerald/2.jpg",
    "../../assets/photos/emerald/3.jpg",
    "../../assets/photos/emerald/4.jpg",
    "../../assets/photos/emerald/5.jpg",
    "../../assets/photos/emerald/6.jpg",
    "../../assets/photos/emerald/7.jpg",
    "../../assets/photos/emerald/8.jpg",
    "../../assets/photos/emerald/9.jpg",
    "../../assets/photos/ruby/1.jpg",
    "../../assets/photos/ruby/2.jpg",
    "../../assets/photos/ruby/3.jpg",
    "../../assets/photos/ruby/4.jpg",
    "../../assets/photos/ruby/5.jpg",
    "../../assets/photos/ruby/6.jpg",
    "../../assets/photos/ruby/7.jpg",
    "../../assets/photos/sapphire/1.jpg",
    "../../assets/photos/sapphire/2.jpg",
    "../../assets/photos/sapphire/3.jpg",
    "../../assets/photos/fines/amethyst/1.jpg",
    "../../assets/photos/fines/amethyst/2.jpg",
    "../../assets/photos/fines/amethyst/3.jpg",
    "../../assets/photos/fines/amethyst/4.jpg",
    "../../assets/photos/fines/amethyst/5.jpg",
    "../../assets/photos/fines/amethyst/6.jpg",
    "../../assets/photos/fines/amethyst/7.jpg",
    "../../assets/photos/fines/aquamarine/1.jpg",
    "../../assets/photos/fines/aquamarine/2.jpg",
    "../../assets/photos/fines/aquamarine/3.jpg",
    "../../assets/photos/fines/citrin/1.jpg",
    "../../assets/photos/fines/citrin/2.jpg",
    "../../assets/photos/fines/citrin/3.jpg",
    "../../assets/photos/fines/citrin/4.jpg",
    "../../assets/photos/fines/citrin/5.jpg",
    "../../assets/photos/fines/citrin/6.jpg",
    "../../assets/photos/fines/spessartite/1.jpg",
    "../../assets/photos/fines/spessartite/2.jpg",
    "../../assets/photos/fines/spessartite/3.jpg",
    "../../assets/photos/fines/spessartite/4.jpg",
    "../../assets/photos/fines/spessartite/5.jpg",
    "../../assets/photos/fines/spessartite/6.jpg",
    "../../assets/photos/fines/spessartite/7.jpg",
    "../../assets/photos/fines/spinel/1.jpg",
    "../../assets/photos/fines/tanzanite/1.jpg",
    "../../assets/photos/fines/tanzanite/2.jpg",
    "../../assets/photos/fines/tanzanite/3.jpg",
    "../../assets/photos/fines/tanzanite/4.jpg",
    "../../assets/photos/fines/tanzanite/5.jpg",
    "../../assets/photos/fines/tanzanite/6.jpg",
    "../../assets/photos/fines/tanzanite/7.jpg",
    "../../assets/photos/fines/tanzanite/8.jpg",
    "../../assets/photos/fines/tanzanite/9.jpg",
    "../../assets/photos/fines/tanzanite/10.jpg",
    "../../assets/photos/fines/tanzanite/11.jpg",
    "../../assets/photos/fines/tourmaline/1.jpg",
    "../../assets/photos/fines/tourmaline/2.jpg",
    "../../assets/photos/fines/tourmaline/3.jpg",
    "../../assets/photos/fines/tourmaline/4.jpg",
    "../../assets/photos/fines/tourmaline/5.jpg",
    "../../assets/photos/fines/tourmaline/6.jpg",
    "../../assets/photos/fines/tourmaline/7.jpg",
    "../../assets/photos/fines/tourmaline/8.jpg",
    "../../assets/photos/fines/tourmaline/9.jpg",
    "../../assets/photos/fines/tourmaline/10.jpg",
    "../../assets/photos/fines/tourmaline/11.jpg",
    "../../assets/photos/fines/tourmaline/12.jpg",
    "../../assets/photos/fines/tourmaline/13.jpg",
    "../../assets/photos/fines/tourmaline/14.jpg",
    "../../assets/photos/fines/tourmaline/15.jpg",
    "../../assets/photos/fines/tourmaline/16.jpg",
    "../../assets/photos/fines/tourmaline/17.jpg",
    "../../assets/photos/fines/tourmaline/18.jpg",
    "../../assets/photos/fines/tourmaline/19.jpg",
    "../../assets/photos/fines/tourmaline/20.jpg",
    "../../assets/photos/fines/tourmaline/21.jpg",
    "../../assets/photos/fines/tourmaline/22.jpg",
    "../../assets/photos/fines/tourmaline/23.jpg"
  ];
}