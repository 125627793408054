import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent }        from './main/main.component';
import { InvestmentComponent }  from './investment/investment.component';
import { StonesComponent }      from './stones/stones.component';
import { ObjectsComponent }     from './objects/objects.component';
import { JewelsComponent }      from './jewels/jewels.component';
import { ContactComponent }     from './contact/contact.component';
import { EstimationComponent }  from './estimation/estimation.component';
import { CatalogueComponent }  from './catalogue/catalogue.component';

const routes: Routes = [
  { path: 'investment', component: InvestmentComponent },
  { path: 'stones', component: StonesComponent },
  { path: 'objects', component: ObjectsComponent },
  { path: 'jewels', component: JewelsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'estimation', component: EstimationComponent },
  { path: 'catalogue', component: CatalogueComponent },
  { path: '', component: MainComponent }
];

@NgModule({
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes) ]
})
export class AppRoutingModule {}
