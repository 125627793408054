import { Component } from '@angular/core';

@Component({
  selector: 'sgt-jewels',
  templateUrl: './jewels.component.html',
  styleUrls: ['./jewels.component.scss']
})
export class JewelsComponent {
  imagesJewels = [
    "../../assets/photos/jewels/1.JPG",
    "../../assets/photos/jewels/2.JPG",
    "../../assets/photos/jewels/3.JPG",
    "../../assets/photos/jewels/4.JPG",
    "../../assets/photos/jewels/5.JPG",
    "../../assets/photos/jewels/6.JPG",
    "../../assets/photos/jewels/7.JPG",
    "../../assets/photos/jewels/8.JPG",
    "../../assets/photos/jewels/9.JPG",
    "../../assets/photos/jewels/10.JPG",
    "../../assets/photos/jewels/11.JPG",
    "../../assets/photos/jewels/12.JPG",
    "../../assets/photos/jewels/13.JPG",
    "../../assets/photos/jewels/14.JPG",
    "../../assets/photos/jewels/15.JPG",
    "../../assets/photos/jewels/16.JPG",
    "../../assets/photos/jewels/17.JPG",
    "../../assets/photos/jewels/18.JPG",
    "../../assets/photos/jewels/19.JPG",
    "../../assets/photos/jewels/20.JPG",
    "../../assets/photos/jewels/21.JPG",
    "../../assets/photos/jewels/22.JPG",
    "../../assets/photos/jewels/23.JPG",
    "../../assets/photos/jewels/24.JPG",
    "../../assets/photos/jewels/25.JPG",
    "../../assets/photos/jewels/26.JPG",
    "../../assets/photos/jewels/27.JPG",
    "../../assets/photos/jewels/28.JPG",
    "../../assets/photos/jewels/29.JPG",
    "../../assets/photos/jewels/30.JPG",
    "../../assets/photos/jewels/31.JPG",
    "../../assets/photos/jewels/32.JPG",
  ];

  imagesJewels2 = [
    "../../assets/photos/jewels/33.JPG",
    "../../assets/photos/jewels/34.JPG",
  ];

  imagesJewels3 = [
    "../../assets/photos/jewels/35.JPG",
    "../../assets/photos/jewels/36.JPG",
    "../../assets/photos/jewels/37.JPG",
    "../../assets/photos/jewels/38.JPG",
  ];
  
  height = "50vh";
}


