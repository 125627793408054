import { Component } from '@angular/core';

@Component({
  selector: 'sgt-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent {
    imagesInvestment = [
    "../../assets/photos/investment/1.jpg",
    "../../assets/photos/investment/2.jpg",
    "../../assets/photos/investment/3.jpg",    
    "../../assets/photos/investment/7.jpg", 
    "../../assets/photos/investment/8.jpg", 
    "../../assets/photos/investment/9.jpg", 
    "../../assets/photos/investment/10.jpg", 
    "../../assets/photos/investment/11.jpg", 
    "../../assets/photos/investment/12.jpg", 
    "../../assets/photos/investment/13.jpg", 
    "../../assets/photos/investment/14.jpg", 
    "../../assets/photos/investment/15.jpg", 
    "../../assets/photos/investment/16.jpg", 
    "../../assets/photos/investment/17.jpg", 
    "../../assets/photos/investment/18.jpg", 
    "../../assets/photos/investment/19.jpg", 
    "../../assets/photos/investment/20.jpg", 
    "../../assets/photos/investment/21.jpg"
  ];


  height = "35vh";
}